.title {
    font-family: 'Satoshi', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 131%;
    letter-spacing: 0.05em;
}

.description {
    margin-top: 24px;
    font-family: 'Satoshi', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 131%;
    letter-spacing: 0.05em;
}

@media screen and (min-width: 900px) {
    .description {
        margin-top: 24px;
        font-family: 'Satoshi', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 131%;
        letter-spacing: 0.05em;
        height: 142px;
    }
}