.deletingWrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0,0,0,0.5);
    transition: opacity 0.3s;
}

.deletingWrap svg {
    fill: red;
    cursor: pointer;
}
.deletingWrap:hover {
    opacity: 1;
}

.content {
    position: relative;
    display: flex;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}