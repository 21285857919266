.requestCode {
    cursor: pointer;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125%;
    text-decoration-line: underline;
    color: #035c9b;
}

.requestCodeWrapper {
    cursor: not-allowed;
}

.disabled {
    color: gray;
    pointer-events: none;
}
