.signInButton {
    cursor: pointer;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125%;
    margin-top: 24px;
    text-decoration-line: underline;
    color: #035c9b;
}

.signUpButton {
    padding-left: 5px;
    cursor: pointer;
    text-decoration-line: underline;
    color: #035c9b;
}

.form {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 600px) {
    .signInButton {
        margin-top: 0;
        width: unset;
    }
}
