.form {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 600px) {
    .form {
        flex-direction: row;
    }
}
