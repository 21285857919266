.page {
    background-color: #3e355c;
    padding: 120px 174px 150px;
}

.textContainer {
    position: relative;
    z-index: 5;
}

.title {
    position: relative;
    font-family: "Montserrat Alternates", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 150%;
    color: #f3ece2;
    z-index: 5;
}

.subtitle {
    color: #978bbf;
    font-family: Satoshi, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 175%;
    max-width: 670px;
    margin-top: 70px;
    margin-bottom: 36px;
}

.legendIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.legendLabel {
    font-family: Satoshi, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
    min-height: 68px;
    margin-top: 2px;
    color: #f3b29d;
}

.legendValue {
    font-family: Satoshi, serif;
    margin-top: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 175%;
    color: #f3b29d;
}

.chart {
    width: 1200px;
    height: 1200px;
}

.chartLabel {
    font-family: "Montserrat Alternates", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 84px;
    line-height: 150%;
}

@media screen and (max-width: 1280px) {
    .chartLabel {
        font-family: "Montserrat Alternates", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 52px;
        line-height: 150%;
    }

    .chartButton {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        padding: 0;
        min-width: 0;
    }
}
