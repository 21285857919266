.label {
    font-family: "Montserrat Alternates", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 170%;
    margin-bottom: 64px;
    letter-spacing: -0.05em;
    color: #000124;
}

@media screen and (min-width: 600px) {
    .label {
        font-size: 28px;
        margin-bottom: 48px;
        align-self: flex-start;
    }
}
