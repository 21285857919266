@media screen and (max-width: 600px) {
    .otherRadioWrapper {
        width: 100%;
        align-items: flex-start;
    }

    .otherInputWrapper {
        width: 100%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }

    .otherInputWrapper input {
        z-index: 100;
    }
}
