.pyramidText {
    font-family: "Montserrat Alternates", serif;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.title {
    font-family: "Montserrat Alternates", serif;
    font-style: normal;
    font-weight: normal;
    max-width: 744px;
    font-size: 80px;
    line-height: 125%;
    letter-spacing: -0.05em;
}

@media screen and (max-height: 870px) {
    .pyramidText {
        font-family: "Montserrat Alternates", serif;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .title {
        font-family: "Montserrat Alternates", serif;
        font-style: normal;
        font-weight: normal;
        max-width: 674px;
        font-size: 42px;
        line-height: 125%;
        letter-spacing: -0.05em;
    }
}

@media screen and (max-width: 800px) {
    .pyramidText {
        font-family: "Montserrat Alternates", serif;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .title {
        font-family: "Montserrat Alternates", serif;
        font-style: normal;
        font-weight: normal;
        max-width: 674px;
        font-size: 42px;
        line-height: 125%;
        letter-spacing: -0.05em;
    }
}
