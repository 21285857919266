.container {
    width: 100%;
    max-width: 400px;
}

button.signInButton {
    padding: 10px 50px;
    width: 100%;
    margin-bottom: 24px;
}

.resetPassword {
    cursor: pointer;
    font-family: Satoshi, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125%;
    text-decoration-line: underline;
    color: #035c9b;
}

.signUpContainer {
    margin-top: 24px;
    text-align: center;
    color: #3e355c;
    font-size: 15px;
}

.signUpButton {
    padding-left: 5px;
    cursor: pointer;
    text-decoration-line: underline;
    color: #035c9b;
}

@media screen and (min-width: 600px) {
    .container {
        min-width: 400px;
    }

    button.signInButton {
        width: unset;
        margin-bottom: 0;
    }

    .signUpContainer {
        margin-top: 32px;
    }
}
