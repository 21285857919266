.dropZone {
    max-width: 336px;
    max-height: 192px;
    min-height: 192px;
    color: #000000;
}

.label {
    font-size: 1rem;
}
.error {
    border-color: #E00000 !important;
}