.horizontal,
.vertical {
    display: flex;
    color: #f3b29d;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
}

.horizontal {
    flex-direction: column;
}

.vertical {
    align-items: center;
}

.horizontal .percents {
    margin-top: 16px;
    text-align: right;
    margin-bottom: 16px;
}

@media screen and (min-width: 600px) {
    .horizontal .percents {
        text-align: left;
        margin-top: 24px;
    }

    .vertical {
        min-width: 400px;
    }

    .horizontal {
        margin-bottom: 24px;
    }
}

.vertical .percents {
    margin-left: 24px;
    min-width: 155px;
}

.progress {
    height: 8px !important;
    width: 100%;
    background: rgb(243 178 157 / 50%) !important;
    border-radius: 15px !important;
}

@media screen and (min-width: 900px) {
    .progress {
        height: 16px !important;
    }
}

.bar {
    background-color: #f3b29d !important;
}
